import cn from 'classnames';
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { RamblerImage } from 'common/components/RamblerComponents/RamblerImage';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { selectPageName } from 'common/redux/appController/selectors';
import {
  selectIsForceOkkoDate,
  selectIsMobile,
  selectOkkoWidgetCountFinishTime,
} from 'common/redux/runtime/selectors';
import {
  selectOkkoWidgetRegions,
  selectOkkoWidgetType,
} from 'common/redux/selectors';
import { rupluralize } from 'utils/date/rupluralize';

import { withErrorBoundary } from '../ErrorBoundary/withErrorBoundary';

import {
  CITYS_BY_REGION_ID,
  OKKO_PIXEL_LINK_BY_REGION_ID,
  OKKO_WIDGET_LINK_BY_REGION_ID,
  PAGES_WITH_OKKO_BANNER,
  WIDGET_GEOS,
  WIDGET_TYPE,
} from './config';
import { EridDropdown } from './EridDropdown';

import s from './styles.module.css';

/**
 * Добавление нуля к значению времени.
 * @param value - значение времени.
 */
const addZero = (value: number) => {
  if (value <= 9) {
    return `0${value}`;
  }

  return value;
};

type OkkoWidgetPropsType = {
  type: 1 | 2;
  isBanner?: boolean;
};

/**
 * Виджет Okko.
 * @param type – тип виджета 1 или 2 какой индекс используем в селекторах
 * @param isBanner – флаг, что виджет будет отображаться в виде баннера
 */
export const OkkoWidget = memo(
  ({ type, isBanner = false }: OkkoWidgetPropsType) => {
    const isMobile = useSelector(selectIsMobile);
    const okkoWidgetRegions = useSelector(
      selectOkkoWidgetRegions(type),
      shallowEqual,
    );
    const forceOkkoDate = useSelector(selectIsForceOkkoDate);
    const { date, time: okkoWidgetCountFinishTime } = useSelector(
      selectOkkoWidgetCountFinishTime(type),
      shallowEqual,
    );

    const timerIdRef = useRef<NodeJS.Timeout | null>(null);

    const okkoWidgetCountFinishDate = forceOkkoDate || date;

    const widgetGeo = okkoWidgetRegions
      ? (Object.keys(WIDGET_GEOS).find((key) =>
          WIDGET_GEOS[key as WIDGET_TYPE].some((id) =>
            okkoWidgetRegions.includes(id),
          ),
        ) as WIDGET_TYPE)
      : undefined;

    const city = widgetGeo ? CITYS_BY_REGION_ID[widgetGeo] : undefined;

    const okkoWidgetLink = widgetGeo
      ? OKKO_WIDGET_LINK_BY_REGION_ID[widgetGeo][isMobile ? 'mob' : 'desk']
      : undefined;

    const pixelLink = widgetGeo
      ? OKKO_PIXEL_LINK_BY_REGION_ID[widgetGeo][isMobile ? 'mob' : 'desk']!
      : undefined;

    const targetDateMilliseconds = useMemo(() => {
      const resetDate = okkoWidgetCountFinishDate
        ? new Date(okkoWidgetCountFinishDate)
        : new Date();
      const hours = Number(okkoWidgetCountFinishTime.split(':')[0]) || 0;

      if (hours >= 0 && hours < 24) {
        resetDate.setHours(hours);
      } else {
        resetDate.setHours(0);
      }

      return resetDate.getTime();
    }, [okkoWidgetCountFinishDate, okkoWidgetCountFinishTime]);

    // Установка переменной currentDateMilliseconds равной targetDateMilliseconds нужна для запрета начала отсчета при SSR
    const [currentDateMilliseconds, setCurrentDateMilliseconds] = useState(
      targetDateMilliseconds,
    );

    const top100Attribute = useTop100AttributeWithValue('promo_widget_timer');

    const diff = targetDateMilliseconds - currentDateMilliseconds;

    const daysLeft = useMemo(
      () => addZero(Math.floor(diff / (1000 * 60 * 60 * 24))),
      [diff],
    );
    const daysLeftPrefix = useMemo(
      () => rupluralize(Number(daysLeft), ['день', 'дня', 'дней']),
      [daysLeft],
    );
    const hoursLeft = useMemo(
      () => addZero(Math.floor((diff / (1000 * 60 * 60)) % 24)),
      [diff],
    );
    const hoursLeftPrefix = useMemo(
      () => rupluralize(Number(hoursLeft), ['час', 'часа', 'часов']),
      [hoursLeft],
    );
    const minutesLeft = useMemo(
      () => addZero(Math.floor((diff / 1000 / 60) % 60)),
      [diff],
    );
    const secondsLeft = useMemo(
      () => addZero(Math.floor((diff / 1000) % 60)),
      [diff],
    );

    // Кейсы, когда при SSR targetDateMilliseconds и currentDateMilliseconds равны, и когда таймер подошел к нулю
    const isHideTimer =
      targetDateMilliseconds <= currentDateMilliseconds ||
      (Number(daysLeft) === 0 &&
        Number(hoursLeft) === 0 &&
        Number(minutesLeft) === 0 &&
        Number(secondsLeft) === 0);

    const countUp = useCallback(() => {
      const milliseconds = new Date().getTime();

      if (milliseconds > targetDateMilliseconds) {
        clearInterval(timerIdRef.current!);

        return;
      }

      setCurrentDateMilliseconds(milliseconds);
    }, [targetDateMilliseconds]);

    useEffect(() => {
      timerIdRef.current = setInterval(() => {
        countUp();
      }, 1000);

      return () => {
        clearInterval(timerIdRef.current!);
      };
    }, [countUp]);

    if (!city) {
      return null;
    }

    return (
      <div className={cn(s.widget, isBanner && s.banner)} {...top100Attribute}>
        <a href={okkoWidgetLink} className={s.link}>
          {!!pixelLink && (
            <RamblerImage
              className={s.pixelImage}
              width={1}
              height={1}
              src={pixelLink}
              isS3={false}
              isLazy={false}
            />
          )}
          <div className={s.content}>
            {!isBanner && (
              <div className={s.title}>
                <span className={s.caption}>До начала в {city} осталось</span>
              </div>
            )}
            <div className={cn(s.timer, { [s.timer_hide]: isHideTimer })}>
              <div className={cn(s.count, s.count_mod)}>
                <span className={s.number}>{daysLeft}</span>
                <span className={s.prefix}>{daysLeftPrefix}</span>
              </div>
              <div className={s.count}>
                <span className={s.number}>{hoursLeft}</span>
                <span className={s.prefix}>{hoursLeftPrefix}</span>
              </div>
              <span className={s.dots}>:</span>
              <div className={s.count}>
                <span className={s.number}>{minutesLeft}</span>
                <span className={s.prefix}>мин.</span>
              </div>
              <span className={s.dots}>:</span>
              <div className={s.count}>
                <span className={s.number}>{secondsLeft}</span>
                <span className={s.prefix}>сек.</span>
              </div>
            </div>
            {!isBanner && <span className={s.label}>Купить билеты</span>}

            <span className={s.footer}>
              0+ Реклама. {!isBanner && <br />}
              ООО «В Контакте»
            </span>
          </div>
        </a>

        <EridDropdown />
      </div>
    );
  },
);

export const OkkoWidgetAnimated = withErrorBoundary(
  function OkkoWidgetAnimated() {
    const okkoWidgetType = useSelector(selectOkkoWidgetType);
    const pageName = useSelector(selectPageName);

    const hasOkkoBanner =
      !!okkoWidgetType && PAGES_WITH_OKKO_BANNER.includes(pageName);

    return (
      <div className={cn(s.animatedWrapper, hasOkkoBanner && s.active)}>
        <div className={s.animatedContent}>
          {hasOkkoBanner && <OkkoWidget type={okkoWidgetType} isBanner />}
        </div>
      </div>
    );
  },
);
