/**
 * Генератор id блока для вставки плеера на основе id кластера и его расположения в бесконечной ленте.
 * @param clusterId - id кластера;
 * @param clusterIndex - индекс кластера в бесконечной ленте.
 */
export const generatePlayerId = (
  clusterId: ClusterData['id'],
  clusterIndex: number,
) => `cluster-${clusterId}-${clusterIndex}`;

/**
 * Генератор id плеера для контейнера, обозначающего границы функционирования миниплеера.
 * @param playerRootId - строчка с уникальным id плеера.
 */
export const generatePlayerIdRoot = (playerId: string) => `${playerId}-root`;
