import { PAGE_TYPE } from 'config/constants/routerName';

export enum WIDGET_TYPE {
  ufa = 'ufa',
  sochi = 'sochi',
  krsk = 'krsk',
  spb = 'spb',
  msk = 'msk',
}

/**
 * Id регионов на которые будет показываться виджет
 */
export const WIDGET_GEOS: { [key in WIDGET_TYPE]: string[] } = {
  [WIDGET_TYPE.ufa]: [
    '54119156',
    '54119155',
    '54119014',
    '54119078',
    '54119110',
    '54119063',
    '54119027',
    '54119117',
  ],
  [WIDGET_TYPE.krsk]: ['54119074', '54119047'],
  [WIDGET_TYPE.sochi]: [
    '54119096',
    '54119025',
    '54119086',
    '54119007',
    '54119092',
  ],
  [WIDGET_TYPE.spb]: [
    '54119023',
    '54119546',
    '54119154',
    '54119161',
    '54119192',
    '54119055',
    '54119041',
    '54119035',
    '54119033',
    '54119174',
  ],
  [WIDGET_TYPE.msk]: [
    '54118936',
    '54119053',
    '54119037',
    '54119120',
    '54119082',
    '54119090',
    '54119045',
    '54118972',
    '54119112',
    '54119080',
    '54119172',
    '54119138',
  ],
};

/**
 * Названия городов по id регионов, покрываем сам город и республику/область в которой он находится
 */
export const CITYS_BY_REGION_ID: { [key in WIDGET_TYPE]: string } = {
  [WIDGET_TYPE.ufa]: 'Уфе',
  [WIDGET_TYPE.krsk]: 'Красноярске',
  [WIDGET_TYPE.sochi]: 'Сочи',
  [WIDGET_TYPE.spb]: 'Санкт-Петербурге',
  [WIDGET_TYPE.msk]: 'Москве',
};

/** Страницы на которых окко-виджет должен быть в формате баннера */
export const PAGES_WITH_OKKO_BANNER = [
  PAGE_TYPE.cluster,
  PAGE_TYPE.clusterComments,
  PAGE_TYPE.editors,
  PAGE_TYPE.autotagTop,
  PAGE_TYPE.consensus,
  PAGE_TYPE.converter,
  PAGE_TYPE.cross,
  PAGE_TYPE.currencies,
  PAGE_TYPE.currency,
  PAGE_TYPE.exchange,
];

type LinkObjectType = Record<
  WIDGET_TYPE,
  {
    desk?: string;
    mob?: string;
  }
>;

/** src страницы на которую ведет виджет !ВАЖНО наличие erid шоб все по закону */
export const OKKO_WIDGET_LINK_BY_REGION_ID: LinkObjectType = {
  [WIDGET_TYPE.ufa]: {
    desk: 'https://trk.mail.ru/c/roe6u8?mt_network=sberseller&mt_sub2=time&mt_sub1=desktop&mt_sub5=SberSeller_VKFest24_Media_WEB_CPM_UFA_all_Shokina_15/05/2024__Time&mt_sub3=UFA&erid=2Ranynn2b3n',
    mob: 'https://trk.mail.ru/c/roe6u8?mt_network=sberseller&mt_sub2=time&mt_sub1=desktop&mt_sub5=SberSeller_VKFest24_Media_WEB_CPM_UFA_all_Shokina_15/05/2024__Time&mt_sub3=UFA&erid=2Ranynn2b3n',
  },
  [WIDGET_TYPE.krsk]: {},
  [WIDGET_TYPE.sochi]: {
    desk: 'https://trk.mail.ru/c/u582f3?mt_network=sberseller&mt_sub2=time&mt_sub1=desktop&mt_sub5=SberSeller_VKFest24_Media_WEB_CPM_SCH_all_Shokina_10/06/2024__Time&mt_sub3=SCH&erid=2RanynopPPN',
    mob: 'https://trk.mail.ru/c/ao26p6?mt_network=sberseller&mt_sub2=time&mt_sub1=mobile&mt_sub5=SberSeller_VKFest24_Media_WEB_CPM_SCH_all_Shokina_10/06/2024__Time&mt_sub3=SCH&erid=2RanynopPPN',
  },
  [WIDGET_TYPE.spb]: {
    desk: 'https://trk.mail.ru/c/aknlf8?mt_network=sberseller&mt_sub2=time&mt_sub1=desktop&mt_sub5=SberSeller_VKFest24_Media_WEB_CPM_SPB_all_Shokina_14/06/2024__Time&mt_sub3=SPB&erid=2RanynXPUFS',
    mob: 'https://trk.mail.ru/c/eni6m3?mt_network=sberseller&mt_sub2=time&mt_sub1=mobile&mt_sub5=SberSeller_VKFest24_Media_WEB_CPM_SPB_all_Shokina_14/06/2024__Time&mt_sub3=SPB&erid=2RanynXPUFS',
  },
  [WIDGET_TYPE.msk]: {},
};

/** src пикселей для сбора статистики баннера */
export const OKKO_PIXEL_LINK_BY_REGION_ID: LinkObjectType = {
  [WIDGET_TYPE.ufa]: {
    desk: 'https://ad.adriver.ru/cgi-bin/rle.cgi?sid=1&bt=55&ad=787688&pid=4031192&bid=10547398&bn=10547398&exss=%rclid%&rnd=1550553758',
    mob: 'https://ad.adriver.ru/cgi-bin/rle.cgi?sid=1&bt=55&ad=787688&pid=4031192&bid=10547398&bn=10547398&exss=%rclid%&rnd=1550553758',
  },
  [WIDGET_TYPE.krsk]: {},
  [WIDGET_TYPE.sochi]: {
    desk: 'https://ad.adriver.ru/cgi-bin/rle.cgi?sid=1&bt=55&ad=787688&pid=4063070&bid=10685802&bn=10685802&exss=%rclid%&rnd=2053888034',
    mob: 'https://ad.adriver.ru/cgi-bin/rle.cgi?sid=1&bt=55&ad=787688&pid=4063071&bid=10685804&bn=10685804&exss=%rclid%&rnd=2103306916',
  },
  [WIDGET_TYPE.spb]: {
    desk: 'https://ad.adriver.ru/cgi-bin/rle.cgi?sid=1&bt=55&ad=787688&pid=4063072&bid=10685813&bn=10685813&exss=%rclid%&rnd=634143023',
    mob: 'https://ad.adriver.ru/cgi-bin/rle.cgi?sid=1&bt=55&ad=787688&pid=4063073&bid=10685827&bn=10685827&exss=%rclid%&rnd=1355825263',
  },
  [WIDGET_TYPE.msk]: {},
};
