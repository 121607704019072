import cn from 'classnames';
import React, { memo, useCallback, useEffect, useState } from 'react';

import { useClickOutside } from 'desktop/hooks/useClickOutside';
import { CloseIcon, DotsIcon } from 'icons';

import s from './styles.module.css';

/**
 * Компонент дропдауна с информацией о рекламодателе, все по закону
 */
export const EridDropdown = memo(function EridDropdown() {
  const [isVisibleDropdown, setIsVisibleDropdown] = useState(false);

  const toggleDropdown = useCallback(() => {
    setIsVisibleDropdown((prevValue) => !prevValue);
  }, []);

  const handleCloseDropdown = useCallback(() => {
    setIsVisibleDropdown(false);
  }, []);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const { code } = event;

      if (code === 'Escape' && isVisibleDropdown) {
        event.preventDefault();
        handleCloseDropdown();
      }
    },
    [handleCloseDropdown, isVisibleDropdown],
  );

  const dropdownRef = useClickOutside<HTMLDivElement>(
    () => setIsVisibleDropdown(false),
    isVisibleDropdown,
  );

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  return (
    <div ref={dropdownRef} className={cn(s.root, isVisibleDropdown && s.open)}>
      <button
        aria-label={
          isVisibleDropdown
            ? 'Скрыть информацию о рекламодателе'
            : 'Показать информацию о рекламодателе'
        }
        className={s.button}
        onClick={toggleDropdown}
        type="button"
        aria-haspopup="true"
        aria-expanded={isVisibleDropdown}
        // TODO(NEWS-0000): a11y заменить на генерирующийся id NEWS-11607
        aria-controls="legal-dropdown"
      >
        {isVisibleDropdown ? (
          <CloseIcon className={s.icon} />
        ) : (
          <DotsIcon className={s.icon} />
        )}
      </button>

      {isVisibleDropdown && (
        // TODO(NEWS-0000): a11y заменить на генерирующийся id NEWS-11607
        <div id="legal-dropdown" className={s.dropdown}>
          Рекламодатель: ООО «В КОНТАКТЕ». ОГРН 1079847035179, ЮР. АДРЕС
          САНК-ПЕТЕРБУРГ, ХЕРСОНСКАЯ УЛ. Д.12-14, ЛИТ.А.ПОМ. 1-Н.; Продавец
          билетов: ООО &quot;Интикетс&quot; 1107023, г. Москва, ул.
          Электрозаводская, 27, стр. 8, ком. 1А. офис «Интикетс», ОГРН
          1157746767364.
        </div>
      )}
    </div>
  );
});
